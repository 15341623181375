<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="header"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ header }}</h3>
        </div>
        <div class="">
            <span v-if="warning"> You can't add more files here. (max: {{
                    max_count
                }})</span><br><br>
            <div v-for="(item, index) in files">
                <span>{{ item.file.name }}</span> (<a href="javascript:" @click="removeFromQueue(index)">remove</a>)
                <div>
                    <progress-bar
                            v-model="item.progress"
                            :type="item.uploaded?'success':undefined"
                            striped
                            :active="item.uploading"/>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="row">
                <div class="col-sm-5 text-left">
                    <vue-switch-with-label :collapse-on-mobile="false" name="save_to_media" v-model="save_to_library"
                                           label="Save to My Media"
                                           v-if="allow_save_to_folders">
                    </vue-switch-with-label>
                </div>
                <div class="col-sm-7 text-right">
                    <div class="row">
                        <div class="col-sm-12">
                            <button @click="cancel()" type="button" class="btn btn-mm btn-default">
                                Cancel
                            </button>
                            <button @click="start_upload()" type="button" class="btn btn-mm  btn-green"
                                    :disabled="upload_started">
                                Upload
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import PopupPromiseMixin from "../../../../vue/mixins/PopupPromiseMixin";
    import axios from "axios";


    class DocumentForUpload {
        constructor(file) {
            this.file = file;
            this.loaded = false;
            this.progress = 0;
            this.uploaded = false;
            this._uploading = false;
        }

        get name() {
            return this.file.name;
        }

        get uploading() {
            return this._uploading;
        }

        set uploading(val) {
            return this._uploading = val;
        }
    }

    export default {
        name: "DocumentUploadPopup",
        mixins: [PopupPromiseMixin],
        data() {
            return {
                header: "Upload Documents",
                files: [],
                url: undefined,
                cancelled: false,
                uploaded_files: [],
                upload_started: false,
                max_count: undefined,
                allow_save_to_folders: undefined,
                save_to_library: true,
                fileToUploadIndex: 0,
                warning: false,
            }
        },
        methods: {
            setInitial(data) {
                this.canceller = new Promise((res) => {
                    this.cancelUpload = res;
                });
                this.files = Array.from(data.initialFiles).map(file => {
                    return new DocumentForUpload(file, this.canceller);
                });
                this.max_count = data.max_count;
                this.removeFiles();
                this.url = data.url;
                this.anonymous_token = data.anonymous_token;
                this.allow_save_to_folders = data.allow_save_to_folders;
            },
            removeFiles() {
                if (this.files.length > this.max_count) {
                    this.warning = true;
                    this.files = this.files.slice(0, this.max_count);
                }
            },
            start_upload() {
                this.upload_started = true;
                this.fileToUploadIndex = 0;
                this.uploadNextFile();
            },
            uploadNextFile() {
                if (this.fileToUploadIndex < this.files.length) {
                    this.documentUpload(this.files[this.fileToUploadIndex])
                        .then((resp) => {
                            this.uploaded_files.push(resp);
                            this.fileToUploadIndex++;
                            this.uploadNextFile();
                        })
                        .catch((e) => {
                            this.upload_started = false;
                            this.progress = 0;
                            if(e.response?.data?.error){
                                this.$notifications.error(e.response?.data?.error);
                            }
                            this.close(e.response?.data?.error || 'error');
                        })
                } else {
                    this.upload_started = false;
                    this.progress = 0;
                    this.save(this.uploaded_files);
                }
            },

            documentUpload(file) {
                if (this.cancelled) {
                    return;
                }
                const formData = new FormData();
                formData.append('file', file.file);
                formData.append('save_to_library', this.allow_save_to_folders && this.save_to_library);

                const headers = {
                    'X-CSRFToken': $csrf_token_var,
                };

                if (this.anonymous_token) {
                    headers['X-A-Token'] = this.anonymous_token;
                }

                this.cancelController = new AbortController();

                return new Promise((resolve, reject) => {
                    axios
                        .post(this.url, formData, {
                            onUploadProgress: (progressEvent) => {
                                file.uploading = true;
                                file.progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                            },
                            headers: headers,
                            signal: this.cancelController.signal,
                        })
                        .then((response) => {
                            resolve(response.data);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
            },
            removeFromQueue(index) {
                this.files.splice(index, 1);
                if (this.files.length === 0) {
                    this.close('back');
                }
            },
            cancel() {
                this.cancelUpload();
                if (this.cancelController) {
                    this.cancelController.abort();
                }
                this.close('cancelled');
            },
        }
    }
</script>
